.hexagon {
  box-sizing: border-box;
  position: absolute;
  margin: 0;
  z-index: 1;
  width: 64px;
  height: calc(64px * 112 / 96);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.hexinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 1px;
  background: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  text-decoration: none;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.hexinner.ocean {
  background-image: radial-gradient(#666, #3e5a66, #45b6ff);
}

.hexinner.ocean.has-owner {
  background: #1c89ff;
}

.hexinner.ocean.special.has-owner,
.hexinner.special.has-owner {
  background: #cbaa83;
}

.hexinner.ocean.greenery.has-owner,
.hexinner.greenery.has-owner {
  background: #6fb34a;
}

.hexinner.ocean.city.has-owner,
.hexinner.city.has-owner {
  background: #b1b1b1;
}

.hexinner.ocean.capital.has-owner,
.hexinner.capital.has-owner {
  background: #ffffff;
}

.hexcontent {
  padding: 0.25em;
  font-size: 0.8em;
  line-height: 1.2em;
}

:host {
  position: absolute;
  min-height: calc(64px * 112 / 96);
}

.bonus0 {
  position: absolute;
  top: 2px;
  left: 26px;
}
.bonus4 {
  position: absolute;
  top: 20px;
  left: 26px;
}

.bonus1 {
  position: absolute;
  top: 12px;
  left: 5px;
}

.bonus2 {
  position: absolute;
  top: 12px;
  right: 6px;
}

.has-owner .hexcontent,
.has-owner .placement_bonuses {
  display: none;
}

.owner {
  width: 20px;
  height: 20px;
  margin-top: 10px;
}

.image-icon {
  position: absolute;
  left: 21px;
  top: 3px;
}

.south-pole {
  width: 100%;
  left: 0;
  right: 0;
  position: absolute;
  top: 10px;

  .money-icon {
    position: absolute;
    left: 8px;
    top: 8px;
  }

  .ocean-icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .minus-money {
    position: absolute;
    top: 6px;
    left: 10px;
    font-size: 16px;
    font-weight: bolder;
    color: black;
  }
}
